<template>
  <div v-if="loader_prop">
    Loading...
  </div>
  <div v-if="! loader_prop">
    Thanks...
  </div>
</template>

<style>
.img_pre {
  max-width: 50em;
  margin-top: 1em;
}
</style>

<script>
import BackendModel from "../../models/BackendModel"

export default {
  name: 'BackofficePaymeTy',
  components: {

  },
  data() {
    return {
      loader_prop : true
    }
  },
  async mounted() {
    let backendModel = new BackendModel()
    let payme_res = await backendModel.backendRequest("/Api/service/backoffice/backoffice_payme_close", {"q_data" : this.$route.query})
    console.log(payme_res)
    this.loader_prop = false
    window.parent.postMessage({
      'func': 'parentFunc',
      'message': 'Message text from iframe.'
    }, "*");
  },
  methods : {
  }
}
</script>
